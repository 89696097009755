import type { CurrencyCode, Scalars, SearchResultPriceFragment } from '#graphql-operations'
import { useI18nLocaleObject } from '~/composables/intl'

export function usePriceFormatter() {
  const { n: numberFormat } = useI18n()
  const localeObject = useI18nLocaleObject()
  const lazyFormatter = useMemoize((amount: number, currencyCode: CurrencyCode = localeObject.value.currency as CurrencyCode) => numberFormat(amount / 100, 'currency', { currency: currencyCode }))

  return (price: Scalars['Money'] | SearchResultPriceFragment | number = 0, currencyCode: CurrencyCode) => {
    if (typeof price === 'number')
      return lazyFormatter(price, currencyCode)

    if ('value' in price)
      return lazyFormatter(price.value, currencyCode)

    if ('min' in price && 'max' in price)
      return price.min === price.max ? lazyFormatter(price.min, currencyCode) : `${lazyFormatter(price.min, currencyCode)} - ${lazyFormatter(price.max, currencyCode)}`

    console.warn('Invalid price', price)
    return lazyFormatter(0, currencyCode)
  }
}
